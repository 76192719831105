.project-page-container {
  min-height: 75vh;
  display: flex;
  flex-direction: row;
}

.project-page-container-mobile {
  display: none;
}

.project-view-container {
  margin: 2rem 0 5rem 5rem;
  width: 75%;
  justify-content: center;
  align-items: center;
}

.project-view-container p {
  text-align: justify;
  padding: 3rem;
}

.project-view-container img {
  width: 800px;
}

.project-list-container {
  margin-top: 2rem;
  text-align: right;
}

.project-list-container ul li {
  list-style: none;
}

@media (max-width: 768px) {
  .project-page-container {
    display: none;
  }

  .project-page-container-mobile {
    display: flex;
    flex-direction: column;
  }

  .project-card {
    background-color: antiquewhite;
    width: 90%;
    margin: auto;
    padding: 0.25rem;
  }

  .project-card p {
    text-align: justify;
    padding: 0.5rem;
  }

  .project-page-container-mobile img {
    width: 70%;
  }
}
