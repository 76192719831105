.navbar {
  display: flex;
  justify-content: space-around;
  background-color: var(--background-color);
  padding: 1rem;
  position: relative;
}

.navbar a {
  text-decoration: none;
  color: var(--text-color);
}

.navbar button {
  background-color: var(--button-background);
  color: var(--button-text);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.mobile-menu-icon {
  display: flex;
  cursor: pointer;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 4px 0;
  background-color: var(--text-color);
  transition: all 0.3s;
}

.bar-rotate-1 {
  transform: translateY(7px) rotate(45deg);
}

.bar-rotate-2 {
  transform: translateY(-7px) rotate(-45deg);
}

.navbar-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  transition: max-height 0.3s;
  overflow: hidden;
  max-height: 0;
}

.navbar-links.show {
  max-height: 200px;
}

.theme-switch-wrapper {
  display: flex;
  align-items: center;
}

em {
  margin-left: 10px;
  font-size: 1rem;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .navbar-links {
    display: flex;
    flex-direction: row;
    max-height: none;
  }
  .mobile-menu-icon {
    display: none;
  }
}
