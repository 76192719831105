.contact-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.2);
  width: 50vw;
  padding: 5vmin;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input {
  border-radius: 1.5vmin;
  width: 35vmin;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.6);
}

.contact-form textarea {
  border-radius: 1.5vmin;
}

.contact-form input,
.btn {
  height: 4vmin;
  /* width: 30vmin; */
  margin: 2vmin;
}

.contact-socials {
  display: flex;
  flex-direction: row;
}

.contact-socials img {
  max-width: 7vmin;
  margin: 3vmin 1vmin 0 1vmin;
}

.btn {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border: solid #1f628d 2px;
  text-decoration: none;
}

.btn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

@media (max-width: 768px) {
  .contact-container {
    width: 65vw;
    padding: 10vmin;
  }

  .btn {
    height: auto;
  }
}
