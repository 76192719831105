html,
body {
  height: 100%;
  margin: 0;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.site-content {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
