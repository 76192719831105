.home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  height: 75vh;
}

.left-info,
.right-info {
  flex: 1;
}

.left-info h1 {
  font-size: 36px;
}

.left-info p {
  font-size: 20px;
  font-style: italic;
}

.right-info p {
  font-size: 16px;
}

.image-container img {
  border-radius: 50%;
  padding: 10vmin;
  height: 30vmin;
}

.image-section {
  text-align: center;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .left-info h1 {
    font-size: 28px;
  }

  .left-info p,
  .right-info p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .left-info h1 {
    font-size: 24px;
  }

  .left-info p,
  .right-info p {
    font-size: 16px;
  }

  img {
    max-width: 1350px;
  }
}

.spacer {
  flex: 1;
}
