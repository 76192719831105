.footer-container {
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer-container img {
  height: 4vmin;
}

.footer-left {
  display: flex;
  flex-direction: row;
}

.footer-left img {
  margin: 2vmin;
}

.footer-container-dark-mode img {
  background-color: white;
  padding: 3px;
  border-radius: 5px;
}

.footer-container-mobile {
  display: none;
}

@media (max-width: 768px) {
  .footer-container {
    visibility: hidden;
  }
  .footer-container-mobile {
    display: flex;
    flex-direction: column;
  }
  .footer-right-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .footer-right-mobile img {
    width: 10vmin;
    margin: 3vmin;
    padding: 2vmin;
  }
}
