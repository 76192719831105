* {
  box-sizing: border-box;
}

.about-me-wrapper {
  min-height: 165vh;
}

.about-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-header h1 {
  z-index: 1;
  opacity: 0;
  animation: slideUp 1s ease-in-out 2s forwards;
  font-size: 5rem;
}

.about-bio {
  padding: 1rem 3rem 0 3rem;
  width: 50vw;
  font-size: 1.15rem;
  text-align: justify;
}

.about-bio li {
  list-style: none;
}

.tech-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tech-images img {
  width: 5rem;
  margin: 0.81rem;
}

@media (width > 769px) {
  .about-me-wrapper-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .about-me-wrapper {
    display: none;
  }
  .about-me-header-mobile {
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .about-me-header-mobile img {
    width: 50%;
    vertical-align: middle;
    margin: 0 -2rem 0 0;
  }
  .header-text {
    z-index: 1;
    opacity: 0;
    animation: slideUp 1s ease-in-out 2s forwards;
    font-size: 1.75rem;
  }
  .about-me-bio-mobile {
    padding: 0 1.5rem 0 1.5rem;
    text-align: justify;
    /* font-family: "Anonymous Pro"; */
  }
  .expertise-wrapper {
    display: flex;
    flex-direction: column;
  }
  .expertise-wrapper img {
    max-width: 5rem;
    padding: 0.5rem;
  }
  .expertise-headings {
    color: gray;
    text-align: left;
    padding: 0 1.5rem 0 1.5rem;
  }
  .about-me-design-mobile {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem 0 1.5rem;
  }
  .about-me-databases {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
  }
  .about-me-backend {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
